'use client';
import { CatalogCategoryMaskbar, CatalogCategoryMiniformat } from '@/entities/catalog-category';
import { MenuPopup } from '@/features/menu';
import { MenuLink } from '@/features/menu/ui/MenuLink';
import { IMenuItem } from '@/shared/api/getMenu/types';
import { useOnClickOutside } from '@/shared/hooks/useOnClickOutside';
import { Portal } from '@/shared/ui/Portal';
import SearchPopup from '@/widgets/SearchPopup/ui/SearchPopup';
import { AnimatePresence } from 'framer-motion';
import { PREFETCH } from '@/shared/constants';
import { FC, useMemo, useRef, useState } from 'react';
import ArrowLeft from '~/icons/arrow-left.svg';
import CloseIcon from '~/icons/close.svg';
import SearchIcon from '~/icons/search_icon.svg';

interface Props {
  onClose: () => void;
  menu: IMenuItem[];
  type?: 'mobile' | 'desktop';
}

const buildHref = (item: IMenuItem): string => {
  let href = `/${item.code}`;

  let current = item.parent;
  while (current) {
    href = `/${current.code}${href}`;
    current = current.parent;
  }

  return `/products${href}`;
};

export const MenuCatalogPopup: FC<Props> = ({ onClose, menu, type = 'desktop' }) => {
  const ref = useRef<HTMLDivElement>(null);
  const [activeIds, setActiveIds] = useState<number[]>([]);

  const customOnClose = () => {
    if (showSearch) return;
    onClose();
  };

  const updateActiveIndexes = (newActiveIndex: number, depth: number) => {
    setActiveIds((prev) => {
      const sliced = prev.slice(0, depth);
      sliced[depth - 1] = newActiveIndex;
      return sliced;
    });
  };

  const linkCols = useMemo(() => {
    if (!activeIds.length) return [menu];

    return activeIds.reduce(
      (acc, i) => {
        if (acc[acc.length - 1][i]?.sections?.length) {
          acc.push(acc[acc.length - 1][i].sections!);
        }

        return acc;
      },
      [menu]
    );
  }, [activeIds]);

  useOnClickOutside(ref, customOnClose, ['.MenuCatalogButton-cvaRoot']);

  const [showSearch, setShowSearch] = useState<boolean>(false);
  const handleClickOpenSearch = () => {
    setTimeout(() => {
      setShowSearch(true);
    }, 200);
  };

  const [curCategory, setCurCategory] = useState('');

  return (
    <MenuPopup onClose={() => customOnClose()} type={type}>
      <AnimatePresence>
        {showSearch ? (
          <Portal className="aboveAllPortal">
            <SearchPopup onClose={() => setShowSearch(false)} onSearch={() => onClose()} />
          </Portal>
        ) : null}
      </AnimatePresence>

      <div ref={ref} className={'flex flex-col md:flex-row gap-1.2 md:h-fit min-h-screen md:px-0 md:pt-0'}>
        <div className="flex md:hidden w-full bg-cLightGrey px-2.4 py-1.2 items-center justify-between">
          {curCategory ? (
            <button
              onClick={(e) => {
                e.stopPropagation();
                setCurCategory('');
                updateActiveIndexes(1, 0);
              }}
            >
              <ArrowLeft className="w-2.2 h-2.2 fill-cBlack" />
            </button>
          ) : (
            <div className="w-2.2 h-2.2" />
          )}
          <h4 className="text-labelL text-cBlack uppercase">{curCategory === '' ? 'Каталог' : curCategory}</h4>
          <button onClick={() => customOnClose()}>
            <CloseIcon className="w-2.4 h-2.4" />
          </button>
        </div>
        {type === 'mobile' ? (
          <button
            onClick={() => handleClickOpenSearch()}
            className="w-[calc(100%-2.8rem)] mx-2.4 py-1.4 px-1.6 flex items-center gap-0.8 bg-cLightGrey rounded-[1.2rem]"
          >
            <span className="text-bodyM text-cBlack48">Поиск по товарам</span>
            <SearchIcon className="w-2 h-2 ml-auto" />
          </button>
        ) : null}
        {type === 'mobile' && linkCols[linkCols.length - 1] ? (
          <div className="md:flex flex flex-col md:grow-0 md:shrink md:basis-[32.3rem] w-full px-2.4">
            {linkCols[linkCols.length - 1].map((i, index) => {
              return (
                <MenuLink
                  type={type}
                  key={i.id}
                  // image={i.picture}
                  href={`${buildHref(i)}`}
                  prefetch={PREFETCH.PRODUCTS}
                  titleSlot={
                    {
                      Maskbar: <CatalogCategoryMaskbar />,
                      Miniformat: <CatalogCategoryMiniformat />,
                    }[i.name] ?? i.name
                  }
                  showArrow={Boolean(i.sections?.length)}
                  isActive={false}
                  onClick={() => {
                    updateActiveIndexes(index, linkCols.length);
                    setCurCategory(i.name);
                  }}
                  className="flex items-center gap-2"
                />
              );
            })}
          </div>
        ) : null}
        {type === 'desktop' &&
          linkCols?.map((linkCol, depth) => (
            <div
              key={depth > activeIds.length ? activeIds.join('') : depth}
              className={'md:flex flex-col md:grow-0 md:shrink md:basis-[32.3rem] w-full'}
            >
              {type === 'desktop' &&
                linkCol.map((i, index) => {
                  return (
                    <MenuLink
                      key={i.id}
                      // image={i.picture}
                      href={`${buildHref(i)}`}
                      prefetch={PREFETCH.PRODUCTS}
                      titleSlot={
                        {
                          Maskbar: <CatalogCategoryMaskbar />,
                          Miniformat: <CatalogCategoryMiniformat />,
                        }[i.name] ?? i.name
                      }
                      showArrow={Boolean(i.sections?.length)}
                      isActive={Boolean(activeIds[depth] === index && i.sections?.length)}
                      onMouseEnter={() => {
                        updateActiveIndexes(index, depth + 1);
                      }}
                    />
                  );
                })}
            </div>
          ))}
      </div>
    </MenuPopup>
  );
};
