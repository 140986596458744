import { useCityStore } from '@/shared/store/cityStore';
import { useHeaderStore } from '@/shared/store/headerStore';
import { twMergeExt } from '@/shared/utils/twMergeExt';
import { cvaInner, cvaOverlay, cvaRoot } from '@/widgets/CallbackPopup/ui/CallbackPopupStyles';
import { clearAllBodyScrollLocks, disableBodyScroll } from 'body-scroll-lock-upgrade';
import { motion } from 'framer-motion';
import Link from 'next/link';
import { FC, useEffect, useRef, useState } from 'react';
import ArrowRight from '~/icons/arrow-right.svg';
import ArrowLeft from '~/icons/ArrowLeft2.svg';
import CloseIcon from '~/icons/close.svg';
import FireIcon from '~/icons/fire.svg';
import FlowerIcon from '~/icons/flower.svg';
import GeoIcon from '~/icons/geo_icon.svg';
import MobileBurgerBg from '~/images/mobile_burger_bg.svg';

interface Props {
  onClose: () => void;
}

const MobileBurger: FC<Props> = ({ onClose }) => {
  const innerRef = useRef(null);

  useEffect(() => {
    if (innerRef.current) {
      disableBodyScroll(innerRef.current, {
        reserveScrollBarGap: true,
      });
    }

    return () => {
      clearAllBodyScrollLocks();
    };
  }, []);

  const [curPart, setCurPart] = useState(0);
  const cityName = useCityStore((state) => state.cityName);

  const getShowCityDefaultFromLocalStorage = useHeaderStore((state) => state.getShowCityDefaultFromLocalStorage);

  const setShowChooseCity = useHeaderStore((state) => state.setShowChooseCity);
  const handleClickChooseCity = () => {
    setShowChooseCity(true);
  };

  useEffect(() => {
    getShowCityDefaultFromLocalStorage();
  }, []);

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{
        duration: 0.3,
        ease: 'easeInOut',
      }}
      className={twMergeExt(cvaRoot())}
      data-lenis-prevent
    >
      <div className={cvaOverlay()} onClick={() => onClose()}></div>
      <div className={cvaInner()} ref={innerRef}>
        <div className="w-full py-1.4 px-2.4 flex justify-between bg-cLightGrey z-20">
          <button className="w-2.4 h-2.4 relative hover:cursor-pointer" onClick={() => setCurPart(0)}>
            {curPart !== 0 ? <ArrowLeft className="w-2.4 h-2.4" /> : null}
          </button>
          <h4 className="text-labelL text-cBlack uppercase">{curPart !== 0 ? mockData[curPart - 1].title : 'Меню'}</h4>
          <button onClick={() => onClose()}>
            <CloseIcon className="w-2.4 h-2.4 relative z-20" />
          </button>
        </div>
        <MobileBurgerBg className="absolute bottom-0 right-0 z-0" />
        <div className="px-0.8 mt-3.2 gap-3.2 z-10 relative">
          {curPart === 0 ? (
            <button
              onClick={() => handleClickChooseCity()}
              className=" bg-cMediumGreyOnWhite px-1.6 py-1.4 rounded-[1.6rem] w-full flex items-center justify-between"
            >
              <div className="flex items-center gap-0.4">
                <GeoIcon className="w-1.6 h-1.6" />
                <span className="text-bodyM text-cBlack">{cityName === 'unknown' ? 'Выберите город' : cityName}</span>
              </div>
              <div className="w-1.6 h-1.6">
                <ArrowRight />
              </div>
            </button>
          ) : null}
          {/* data */}
          <div className="w-full flex flex-col gap-2.4 mt-3.2 px-2.4">
            {curPart !== 0
              ? mockData[curPart - 1].child?.map((i, index) => {
                  if (i.link) {
                    return (
                      <Link
                        href={i.link}
                        key={index}
                        className="w-full flex items-center justify-between"
                        prefetch={false}
                      >
                        {i.title}
                        <div className="w-1.6 h-1.6"></div>
                      </Link>
                    );
                  }

                  return (
                    <div key={index} className="w-full flex items-center justify-between">
                      {i.title}
                      <div className="w-1.6 h-1.6"></div>
                    </div>
                  );
                })
              : mockData.map((i, index) => {
                  if (i.link) {
                    return (
                      <Link
                        key={index}
                        className="w-full flex items-center justify-between hover:cursor-pointer"
                        href={i.link}
                        onClick={() => {
                          if (i.child) {
                            setCurPart(index + 1);
                          }
                        }}
                      >
                        {i.title}
                        {i.title === 'Sale' ? (
                          <div className="w-1.6 h-1.6 mr-auto ml-[5px]">
                            <FireIcon className="text-[#FFB016]" />
                          </div>
                        ) : null}
                        {i.title === 'Новинки' ? (
                          <div className="w-1.6 h-1.6 mr-auto ml-[5px]">
                            <FlowerIcon className="text-cMediumGreen" />
                          </div>
                        ) : null}

                        <div className="w-1.6 h-1.6">{i.child ? <ArrowRight /> : null}</div>
                      </Link>
                    );
                  }
                  return (
                    <div
                      key={index}
                      className="w-full flex items-center justify-between hover:cursor-pointer"
                      onClick={() => {
                        if (i.child) {
                          setCurPart(index + 1);
                        }
                      }}
                    >
                      {i.title}

                      <div className="w-1.6 h-1.6">{i.child ? <ArrowRight /> : null}</div>
                    </div>
                  );
                })}
          </div>
        </div>
      </div>
    </motion.div>
  );
};

export default MobileBurger;

const mockData = [
  {
    title: 'Об ALOEsmart',
    child: [
      {
        title: 'Магазины',
        link: process.env.NEXT_PUBLIC_FRONT_BASE_URL + '/about/shops/',
      },
      {
        title: 'Отзывы',
        link: process.env.NEXT_PUBLIC_FRONT_BASE_URL + '/instagram/',
      },
      {
        title: 'Новости',
        link: process.env.NEXT_PUBLIC_FRONT_BASE_URL + '/news/',
      },
      {
        title: 'Контакты',
        link: process.env.NEXT_PUBLIC_FRONT_BASE_URL + '/contacts/',
      },
    ],
  },
  {
    title: 'Sale',
    link: '/products/sale/',
  },
  {
    title: 'Новинки',
    link: '/products/new/',
  },
  {
    title: 'Клиентам',
    child: [
      {
        title: 'Доставка',
        link: process.env.NEXT_PUBLIC_FRONT_BASE_URL + '/delivery/',
      },
      {
        title: 'Подарочный сертификат',
        link: process.env.NEXT_PUBLIC_FRONT_BASE_URL + '/certificate/',
      },
      {
        title: 'Возврат',
        link: process.env.NEXT_PUBLIC_FRONT_BASE_URL + '/garantiya-na-tovar/',
      },
      {
        title: 'Бонусная карта',
        link: process.env.NEXT_PUBLIC_FRONT_BASE_URL + '/bonus/',
      },
      {
        title: 'Гарантия',
        link: process.env.NEXT_PUBLIC_FRONT_BASE_URL + '/authenticity/',
      },
    ],
  },
  {
    title: 'Бренды',
    link: process.env.NEXT_PUBLIC_FRONT_BASE_URL + '/brands/',
  },
  {
    title: 'Акции',
    link: '/products/sale',
  },
];
