import(/* webpackMode: "eager" */ "/app/node_modules/next/font/local/target.css?{\"path\":\"src/app/layout.tsx\",\"import\":\"\",\"arguments\":[{\"src\":[{\"path\":\"../../public/fonts/RFDewi-Regular.woff2\",\"weight\":\"400\",\"style\":\"normal\"},{\"path\":\"../../public/fonts/RFDewi-Semibold.woff2\",\"weight\":\"600\",\"style\":\"normal\"},{\"path\":\"../../public/fonts/RFDewi-Bold.woff2\",\"weight\":\"700\",\"style\":\"normal\"}],\"display\":\"swap\",\"variable\":\"--base-font\"}],\"variableName\":\"RFDewi\"}");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/font/local/target.css?{\"path\":\"src/app/layout.tsx\",\"import\":\"\",\"arguments\":[{\"src\":[{\"path\":\"../../public/fonts/SangBleuSunrise-Regular-WebXL.woff2\",\"weight\":\"400\",\"style\":\"normal\"},{\"path\":\"../../public/fonts/SangBleuSunrise-Livre-WebXL.woff2\",\"weight\":\"450\",\"style\":\"normal\"},{\"path\":\"../../public/fonts/SangBleuSunrise-Medium-WebXL.woff2\",\"weight\":\"500\",\"style\":\"normal\"},{\"path\":\"../../public/fonts/SangBleuSunrise-Bold-WebXL.woff2\",\"weight\":\"700\",\"style\":\"normal\"}],\"display\":\"swap\",\"variable\":\"--secondary-font\"}],\"variableName\":\"SangBleuSunrise\"}");
;
import(/* webpackMode: "eager", webpackExports: ["__esModule","default"] */ "/app/node_modules/nextjs-toploader/dist/index.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/simplebar-react/dist/simplebar.min.css");
;
import(/* webpackMode: "eager" */ "/app/node_modules/swiper/swiper-bundle.css");
;
import(/* webpackMode: "eager" */ "/app/node_modules/react-tooltip/dist/react-tooltip.min.css");
;
import(/* webpackMode: "eager" */ "/app/src/app/globals.css");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/features/query-provider/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["RemResizeScript"] */ "/app/src/features/rem-resize/ui/RemResizeScript.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["SmoothScroll"] */ "/app/src/features/smooth-scroll/ui/SmoothScroll.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/shared/ui/Backdrop/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Metrika"] */ "/app/src/shared/ui/Metrika/metrika.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Footer"] */ "/app/src/widgets/footer/ui/Footer.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Header"] */ "/app/src/widgets/header/ui/Header.tsx");
